





























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AssignCard extends Vue {
  @Prop({ default: "" }) readonly bgSrc!: string;
  @Prop({ default: "left" }) readonly assign!: string;
  @Prop({ default: "6rem" }) readonly btnBottom!: string;
}
